import React, { useState } from "react";
import Cookies from "universal-cookie";

// Components
import Header from "../components/Header";
import Layout from "../components/Layout";
import Seo from "../components/SearchEngineOptimalization";

export default function PrivacyPolicy() {
  const cookies = new Cookies();
  const [cookieConsent, setCookieConsent] = useState(() => {
    if (cookies.get("root-gdpr-cookies")) {
      return cookies.get("root-gdpr-cookies");
    }
    return false;
  });

  function handleCookie(e) {
    setCookieConsent(! cookieConsent);
    cookies.remove("root-gdpr-cookies", {
      path: "/",
      sameSite: "lax",
      secure: true,
    });
    window.location.reload();
  }

  return (
    <Layout>
      <Seo title="Privacy Policy" />
      <Header type="dark" />

      <main id="content" className="l-page">
        <div className="container--narrow">
          <article className="l-page__inner entry-content">
            <h1 className="l-page__title">Privacy Policy</h1>
            <p className="l-page__meta">Updated on 14 May 2022</p>
            <h2>Cookies</h2>
            <p>If you accept the cookies in the consent banner, you accept <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">Google Analytics tracking</a>, which uses cookies.</p>
            <p>We store this choice of you in the <code>root-gdpr-cookies</code> cookie.</p>
            {cookieConsent ?
              <div>
                <p>If you want to opt-out or accept our cookies, please press the button below.</p>
                <div>
                  <button className="btn btn--decline" onClick={handleCookie}>Decline Cookies</button>
                </div>
              </div>
              : ''
            }
            <h2>Embedded content from other websites</h2>
            <p>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</p>
            <p>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.</p>
          </article>
        </div>
      </main>
    </Layout>
  );
}
